// About.js
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const About = () => {
  const animation = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.section id="about" style={animation}>
      <h2>About Me</h2>
      <HelmetProvider>
      <Helmet>
        <meta name="description" content="Welcome to my About me page, this section contains friendly information about me" />
      </Helmet>
      <p>
        Hi there! I'm Akash Balani, a passionate Cloud Engineer with a keen interest in
        creating responsive and user-friendly web applications. I have a strong
        foundation in Cloud and DevOps technologies.
      </p>
      <p>
        In my free time, I enjoy exploring new places to hike, boxing, and sharing my knowledge with the community.
      </p>
      </HelmetProvider>
    </animated.section>
  );
}

export default About;
