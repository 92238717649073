// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}

section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: 45%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
}

#contact ul {
  list-style-type: none;
  padding: 0;
}

#contact li {
  margin-bottom: 10px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 10px;
}

nav a {
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.projects-list {
  display: flex;
  flex-wrap: wrap; 
}

.project {
  width: 100%;
  padding: 15px; 
  box-sizing: border-box; 
}

/* @media only screen and (min-width: 600px) {
  .project {
    width: calc(50% - 30px);
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .project {
    width: calc(33.333% - 30px);
  }
} */

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,gCAAgC;EAChC,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;;;;;;;;;;;GAWG","sourcesContent":["/* App.css */\nbody {\n  font-family: 'Arial', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\nheader {\n  background-color: #333;\n  color: #fff;\n  text-align: center;\n  padding: 20px;\n}\n\nsection {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.projects-list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.project {\n  width: 45%;\n  margin: 10px;\n  padding: 10px;\n  border: 1px solid #ddd;\n}\n\n#contact ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n#contact li {\n  margin-bottom: 10px;\n}\n\nnav ul {\n  list-style: none;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n}\n\nnav li {\n  margin: 0 10px;\n}\n\nnav a {\n  color: #fff;\n  text-decoration: none;\n}\n\nnav a:hover {\n  text-decoration: underline;\n}\n\n.projects-list {\n  display: flex;\n  flex-wrap: wrap; \n}\n\n.project {\n  width: 100%;\n  padding: 15px; \n  box-sizing: border-box; \n}\n\n/* @media only screen and (min-width: 600px) {\n  .project {\n    width: calc(50% - 30px);\n    margin-right: 30px;\n  }\n}\n\n@media only screen and (min-width: 768px) {\n  .project {\n    width: calc(33.333% - 30px);\n  }\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
